export const secretWordsArray = [
"ABORA",
"ABUJA",
"AFOTO",
"AGORA",
"ALIMA",
"AMOTO",
"ANGOR",
"ANQUE",
"APOSO",
"ARDIL",
"ATOBA",
"AVARO",
"BALEO",
"BANCO",
"BARZA",
"BINZA",
"BORIA",
"BREAR",
"BUFAO",
"BUFAR",
"BUCHE",
"CAGAO",
"CHEPA",
"CHINO",
"CIECA",
"CLOCA",
"COCON",
"COFIN",
"CORBA",
"CORCA",
"CUALO",
"CUASI",
"CUNAO",
"DENDE",
"DISTA",
"ESNUO",
"FALCA",
"FOLLA",
"FOSCA",
"FOSCO",
"FUSTE",
"GALUA",
"GARBA",
"GINAR",
"GIRAR",
"GRAPA",
"GUITA",
"HARTA",
"HELOR",
"HUMAR",
"JALAR",
"JIPAR",
"JUDIO",
"JULAY",
"LEGON",
"MAERE",
"MANIS",
"MEQUE",
"MERLA",
"MESMO",
"MIAJA",
"MINSO",
"MISTO",
"MOJAR",
"MOLLA",
"OLIVA",
"ORAJE",
"PAERE",
"PAINE",
"PAJUZ",
"PANZA",
"PARAR",
"PARVA",
"PAVOR",
"PERFA",
"PEROL",
"PESOL",
"PIAZO",
"POTRA",
"POZAL",
"PROBE",
"RAIJO",
"RECIO",
"REGLE",
"ROBIN",
"RULAR",
"RUMBO",
"SAQUE",
"SERON",
"SETON",
"SIGUN",
"SIRRE",
"SISCA",
"SOPAR",
"TABLA",
"TIRAR",
"TOICO",
"TROLA",
"TRUJE",
"VISCO",
"ZAGAL",
"ZARZO",
"ZENIA"
]
