import React from 'react';

export const Title = () => {
  return (
    <div className='title'>
        <div className="w-75 container text-center  "> 
            <h1 className='title'>GUORDEL</h1>
            <hr />
        </div>
    </div>
  )
};
